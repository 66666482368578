body {
  margin: 0;
  padding-right: 0px;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

#root {
  height: 100%;
  width: 100%;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #ee7404;
  border-radius: 10px;
}
